import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import Barcode from 'react-barcode'

import {generateTypography, getItem, ProductTitle} from '../BarcodeLabels'
import getConsignorInitials from './get-consignor-initials'

const Template11 = ({bottomStrings, item, isDefaultSize, showConsignorCode}) => {
  return (
    <Box
      key={item?.id}
      flex={1}
      height={isDefaultSize ? '172px' : '180px'}
      maxHeight={isDefaultSize ? '172px' : '180px'}
      overflow="hidden"
      border="1px solid white"
      position="relative"
    >
      <Stack
        direction="row"
        border="1px solid white"
        width={isDefaultSize ? '500px' : '314px'}
        height={isDefaultSize ? '180px' : '175px'}
        maxHeight={isDefaultSize ? '180px' : '175px'}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          overflow="hidden"
          minWidth={isDefaultSize ? '40%' : '35%'}
          width={isDefaultSize ? '40%' : '35%'}
          border="1px solid white"
        >
          <Stack>
            <Box
              sx={{
                position: 'relative',
                minHeight: '50px',
                maxHeight: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px',
                border: '1px solid white',
              }}
            >
              <ProductTitle
                color="black"
                variant="body1"
                fontSize={isDefaultSize ? 'clamp(14px, 1.2vw, 17px)' : 'clamp(8px, 1vw, 10px)'}
                fontWeight="normal"
              >
                {item?.title || 'n/a'}
              </ProductTitle>
            </Box>
            <Box>
              {generateTypography(getItem('size', item), {
                fontSize: isDefaultSize ? '1rem' : '0.8rem',
                textAlign: 'center',
              })}
            </Box>
          </Stack>
          {bottomStrings.map((str, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
              visibility={str?.isHidden ? 'hidden' : ''}
            >
              {generateTypography(getItem(str?.id, item), {
                fontSize: isDefaultSize ? '2rem' : '1.2rem',
              })}
            </Box>
          ))}

          {/* Footer */}
          <Box
            sx={{
              width: '40px',
              position: 'absolute',
              zIndex: 10,
              height: '25px',
              maxHeight: '25px',
              marginTop: isDefaultSize ? '140px' : '150px',
              marginLeft: isDefaultSize ? '460px' : '270px',
            }}
          >
            {showConsignorCode && (
              <Typography color="black" fontWeight={600}>
                C{getConsignorInitials(item?.consign?.consignor)}
              </Typography>
            )}
          </Box>
        </Stack>

        <Stack flex={1} alignItems="center" justifyContent="center">
          <Barcode value={item.internalSku} fontOptions="bold" />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Template11
