import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import Barcode from 'react-barcode'

import {generateGridItems, generateTypography, getItem, ProductTitle} from '../BarcodeLabels'
import getConsignorInitials from './get-consignor-initials'
import Logo from './logo'

const Template10 = ({bottomStrings, topStrings, item, isDefaultSize, showConsignorCode}) => {
  return (
    <Box
      key={item?.id}
      flex={1}
      height={isDefaultSize ? '172px' : '180px'}
      maxHeight={isDefaultSize ? '172px' : '180px'}
      overflow="hidden"
      border="1px solid white"
      position="relative"
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        width={isDefaultSize ? '500px' : '314px'}
        border="1px solid white"
        height={isDefaultSize ? '180px' : '175px'}
        maxHeight={isDefaultSize ? '180px' : '175px'}
        gap={0.5}
        overflow="hidden"
      >
        <Stack direction="row">
          <Box
            width="35%"
            maxHeight={isDefaultSize ? '60px' : '50px'}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            border="1px solid white"
          >
            <Logo
              sx={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
                width: '100%',
                height: '100%',
                '@media print': {
                  maxWidth: '100%',
                  maxHeight: '100%',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              maxWidth: isDefaultSize ? '500px' : '314px',
              height: '50px',
              maxHeight: '50px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'break-spaces',
              wordBreak: 'break-word',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              WebkitLineClamp: 2,
              border: '1px solid white',
            }}
          >
            <ProductTitle
              color="black"
              variant="body1"
              fontSize={isDefaultSize ? 'clamp(18px, 2.5vw, 20px)' : 'clamp(14px, 2vw, 18px)'}
            >
              {item?.title || 'n/a'}
            </ProductTitle>
          </Box>
        </Stack>

        {/* Body */}
        <Box
          height={isDefaultSize ? '160px' : '170px'}
          maxHeight={isDefaultSize ? '160px' : '175px'}
          border="1px solid white"
        >
          <Stack direction="row" px={1} gap={1} justifyContent="space-evenly">
            {bottomStrings.map((str, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                visibility={str?.isHidden ? 'hidden' : ''}
              >
                {generateTypography(getItem(str?.id, item), {
                  fontSize: isDefaultSize ? '2rem' : '1.2rem',
                })}
              </Box>
            ))}
            <Stack gap={0.5}>
              {generateGridItems(topStrings, isDefaultSize ? '0.9rem' : '0.8rem', item, isDefaultSize)}
            </Stack>
            <Barcode
              value={item.internalSku}
              fontOptions="bold"
              height={isDefaultSize ? 60 : 60}
              width={isDefaultSize ? 2.5 : 2}
              fontSize={isDefaultSize ? 18 : 16}
            />
          </Stack>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            width: '40px',
            position: 'absolute',
            zIndex: 10,
            height: '25px',
            maxHeight: '25px',
            marginTop: isDefaultSize ? '140px' : '150px',
            marginLeft: isDefaultSize ? '460px' : '270px',
          }}
        >
          {showConsignorCode && (
            <Typography color="black" fontWeight={600}>
              C{getConsignorInitials(item?.consign?.consignor)}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  )
}

export default Template10
