import {isEmpty} from 'lodash'

import {_getInitialsFullName, getInitials} from 'util/string_utils'

export const getConsignorInitials = (consignor) => {
  const {applications, firstName, lastName} = consignor || {}

  return !isEmpty(applications)
    ? _getInitialsFullName(applications?.[0]?.fullname)
    : `${getInitials(firstName)}${getInitials(lastName)}`
}

export default getConsignorInitials
