import React from 'react'
import {Stack} from '@mui/material'
import Barcode from 'react-barcode'

import Logo from './logo'

const Template8 = ({isDefaultSize, internalSku}) => {
  return (
    <Stack direction="row" width="100%" height="100%" px={2} justifyContent="space-evenly">
      <Stack
        justifyContent="space-evenly"
        width="30%"
        maxWidth={isDefaultSize ? '30%' : '35%'}
        maxHeight={isDefaultSize ? '130px' : '200px'}
        alignItems="center"
        textAlign="center"
      >
        <Logo sx={{objectFit: 'contain'}} />
      </Stack>
      <Barcode
        value={internalSku}
        fontOptions="bold"
        height={isDefaultSize ? 40 : 30}
        width={isDefaultSize ? 2 : 1.2}
        fontSize={18}
      />
    </Stack>
  )
}

export default Template8
