import React, {forwardRef} from 'react'
import {Box, Typography, Stack} from '@mui/material'
import {isEmpty} from 'lodash'
import {styled} from '@mui/material/styles'

import {getCurrency} from 'util/model/setting'
import {isConsigned} from 'util/model/product'
import {_getValue} from 'util/string_utils'
import {QRPrintPreview} from './barcode-template'
import {
  getConsignorInitials,
  Template1,
  Template4,
  Template5,
  Template7,
  Template8,
  Template9,
  Template10,
  Template11,
  TemplateDefault,
} from './barcode-print-preview'

export const getItem = (key, item) => {
  const currency = getCurrency()
  if (key === 'price' || key === 'desiredReturn') {
    return currency.format(_getValue(item[key]))
  }

  return item[key] || ''
}

export const generateGridItems = (
  strings,
  fontSize,
  item,
  isDefaultSize,
  shouldHideKey = false,
  _style,
) => {
  return strings.map((str, index) => {
    const value = getItem(str?.id, item)

    return (
      <Box
        key={index}
        lineHeight={isDefaultSize ? '17px' : '14px'}
        color="black"
        fontWeight={600}
        fontSize={fontSize}
        visibility={str?.isHidden ? 'hidden' : ''}
        display={str?.isHidden && shouldHideKey ? 'none' : ''}
      >
        {value}
      </Box>
    )
  })
}

export const ProductTitle = styled(Typography)(({theme}) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  lineHeight: '1.2',
  ...theme,
}))

export const generateTypography = (text, style) => (
  <Typography {...style} color="black" fontWeight={600}>
    {text}
  </Typography>
)

const BarcodeLabels = forwardRef(({items, barcodeTemplates, logo, showBarcodeTemplate, url}, ref) => {
  const {printSize, topStrings, bottomStrings, template} = barcodeTemplates || {}
  const hasConsignorCode = showBarcodeTemplate?.consignorCode

  const hasNoTitle = [8, 9].includes(template)

  const isDefaultSize = printSize.width === 3.5

  const width = isDefaultSize ? 336 : 211.2
  const height = isDefaultSize ? 115.2 : 120

  const printStyles = `
    @media print {
      @page {
        size: ${width}px ${height}px;
        margin-top: 0 !important;
        padding-top: 0 !important;
      }
    }
  `

  const printBody = (item) => {
    if (template === 1) {
      // Template 2 in the BarcodeTemplate Modal
      return (
        <Template1
          item={item}
          isDefaultSize={isDefaultSize}
          bottomStrings={bottomStrings}
          topStrings={topStrings}
        />
      )
    } else if (template === 4) {
      // Template 5 in the BarcodeTemplate Modal
      return (
        <Template4
          item={item}
          isDefaultSize={isDefaultSize}
          bottomStrings={bottomStrings}
          topStrings={topStrings}
        />
      )
    } else if (template === 5) {
      // Template 6 in the BarcodeTemplate Modal
      return <Template5 item={item} isDefaultSize={isDefaultSize} topStrings={topStrings} />
    } else if (template === 7) {
      // Template 8 in the BarcodeTemplate Modal
      return (
        <Template7
          item={item}
          isDefaultSize={isDefaultSize}
          bottomStrings={bottomStrings}
          topStrings={topStrings}
        />
      )
    } else if (template === 8) {
      // Template 9 in the BarcodeTemplate Modal
      return <Template8 internalSku={item?.internalSku} isDefaultSize={isDefaultSize} />
    } else if (template === 9) {
      // Template Store Location in the BarcodeTemplate Modal
      return (
        <Template9
          internalSku={item?.internalSku}
          isDefaultSize={isDefaultSize}
          location={getItem(topStrings[0], item)}
        />
      )
    }

    return (
      <TemplateDefault
        item={item}
        isDefaultSize={isDefaultSize}
        topStrings={topStrings}
        bottomStrings={bottomStrings}
      />
    )
  }

  if (showBarcodeTemplate?.template === 'qr') {
    return (
      <Box ref={ref}>
        <QRPrintPreview items={items} url={url} />
      </Box>
    )
  }

  return (
    <Box ref={ref}>
      <style>{printStyles}</style>
      {items.map((item, index) => {
        const showConsignorCode = hasConsignorCode && isConsigned(item)

        const titleColor = !hasNoTitle && !isEmpty(item?.title) ? 'black' : 'white'

        if (template === 10) {
          return (
            <Template10
              isDefaultSize={isDefaultSize}
              showConsignorCode={showConsignorCode}
              item={item}
              bottomStrings={bottomStrings}
              topStrings={topStrings}
            />
          )
        } else if (template === 11) {
          return (
            <Template11
              isDefaultSize={isDefaultSize}
              showConsignorCode={showConsignorCode}
              item={item}
              bottomStrings={bottomStrings}
            />
          )
        }

        return (
          <Box
            key={index}
            flex={1}
            height={isDefaultSize ? '172px' : '180px'}
            maxHeight={isDefaultSize ? '172px' : '180px'}
            overflow="hidden"
            border="1px solid white"
            position="relative"
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              width={isDefaultSize ? '500px' : '314px'}
              border="1px solid white"
              height={isDefaultSize ? '180px' : '175px'}
              maxHeight={isDefaultSize ? '180px' : '175px'}
              gap={0.5}
              overflow="hidden"
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: isDefaultSize ? '500px' : '314px',
                  height: '40px',
                  maxHeight: '40px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '5px',
                  WebkitLineClamp: 2,
                  border: '1px solid white',
                }}
              >
                <ProductTitle
                  color={titleColor}
                  variant="body1"
                  fontSize={isDefaultSize ? 'clamp(18px, 2.5vw, 20px)' : 'clamp(14px, 2vw, 18px)'}
                >
                  {hasNoTitle ? 'n/a' : item.title || 'n/a'}
                </ProductTitle>
              </Box>

              {/* Body */}
              <Box
                height={isDefaultSize ? '160px' : '170px'}
                maxHeight={isDefaultSize ? '160px' : '175px'}
                border="1px solid white"
              >
                {printBody(item)}
              </Box>

              {/* Footer */}
              <Box
                sx={{
                  width: '40px',
                  position: 'absolute',
                  zIndex: 10,
                  height: '25px',
                  maxHeight: '25px',
                  marginTop: isDefaultSize ? '140px' : '150px',
                  marginLeft: isDefaultSize ? '460px' : '270px',
                }}
              >
                {showConsignorCode && (
                  <Typography color="black" fontWeight={600}>
                    C{getConsignorInitials(item?.consign?.consignor)}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Box>
        )
      })}
    </Box>
  )
})

export default BarcodeLabels
