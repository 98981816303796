import {memo, useCallback, useMemo, useRef, useState} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  LinearProgress,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import {QrCodeScanner, KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon} from '@mui/icons-material'
import {isEmpty} from 'lodash'
import SelectDropdown from 'react-select'
import {Input} from 'reactstrap' // Todo: update this to mui/material
import {useReactToPrint} from 'react-to-print'

import {ModalType} from 'enums'
import {box_options, condition_options} from 'assets/data'
import {useBulkScanIn, useUser} from 'service/hook'
import {useGlobalStore} from 'provider/global_store/hook'
import {BarcodeLabels} from 'views_v2/lib/components'
import {CopytButton, CopytLabel, EmptyData} from 'views_v2/lib/snippets'
import {CustomButton} from '../Inventory/snippets/headers'
import {COLORS} from '../Dashboard/constants'
import {BulkScanInAutofill, useBulkScanInStore} from './useBulkScanInStore'
import BulkScanInModals from './BulkScanInModals'
import Scanned from './sections/scanned'

// const testUpc = '0194276050354'
// const testUpc = '0885259846193'

const BulkScanIn = () => {
  const {
    locations,
    scanUpc,
    createProducts,
    listProducts,
    duplicateProduct,
    currentDescriptionWildcardList,
  } = useBulkScanIn()
  const {isEnterprise} = useGlobalStore()

  const {user} = useUser()

  const bulkScanInPayload = useBulkScanInStore((state) => state.payload)
  const bulkScanInSettings = useBulkScanInStore((state) => state.settings)
  const bulkScanInUpdateSettings = useBulkScanInStore((state) => state.updateSettings)
  const bulkScanInUpdateLocation = useBulkScanInStore((state) => state.updateLocation)
  const bulkScanInClearProducts = useBulkScanInStore((state) => state.clearProducts)

  const [search, setSearch] = useState('')
  const [modalType, setModalType] = useState(undefined)
  const [selectedProduct, setSelectedProduct] = useState(undefined)
  const [barcodeTemplates, setBarcodeTemplates] = useState([])
  const [showBarcodeTemplate, setShowBarcodeTemplate] = useState({})
  const [printSize, setPrintSize] = useState({width: 3.5, height: 1.2})
  const [isBarcodeLabelReady, setIsBarcodeLabelReady] = useState(false)
  const [pagination, setPagination] = useState({page: 1, limit: 50})
  const [listedProducts, setListedProducts] = useState([])
  const [createdProducts, setCreatedProducts] = useState([])

  const barcodeComponentRef = useRef()

  const locationOptions = useMemo(
    () => locations.data?.map(({id, name}) => ({label: name, value: id})) || [],
    [locations.data],
  )

  const handleScanUpc = useCallback(
    async (e) => {
      const upc = e.target.value
      if (!e.target.value) return
      if (e.key === 'Enter') {
        e.target.value = ''
        await scanUpc.mutateAsync(upc)
      }
    },
    [scanUpc],
  )

  const handlePrint = useReactToPrint({
    content: () => barcodeComponentRef.current,
    suppressErrors: true,
    documentTitle: `Copyt - ${printSize.width}x${printSize.height}`,
  })

  const onGenerateBarcodesClicked = async (e, isListed = true) => {
    setIsBarcodeLabelReady(true)
    setTimeout(() => {
      try {
        handlePrint()
      } catch (err) {
        console.error(err)
        if (err.response && err.response.status == 401) {
          setToastType(ToastType.ERROR)
        }
      }
    }, 500)
  }

  const filteredProducts = useMemo(() => {
    return bulkScanInPayload?.products.filter((product) => {
      const searchTerm = search.toLowerCase()
      return (
        product?.sku?.toLowerCase().includes(searchTerm) ||
        product?.title?.toLowerCase().includes(searchTerm) ||
        product?.boxCondition?.toLowerCase().includes(searchTerm) ||
        product?.condition?.toLowerCase().includes(searchTerm) ||
        product?.subLocation?.toLowerCase().includes(searchTerm) ||
        product?.consignorEmail?.toLowerCase().includes(searchTerm)
      )
    })
  }, [search, bulkScanInPayload?.products])

  return (
    <Stack height="95%">
      <Box bgcolor="var(--lighter)" borderRadius="0 0 5px 5px" zIndex={10}>
        <Accordion expanded={bulkScanInSettings.isExpanded}>
          <AccordionSummary onClick={(e) => e.stopPropagation()} sx={{width: '100%'}}>
            <Stack
              direction="row"
              gap={2}
              justifyContent="space-between"
              width="100%"
              alignContent="center"
            >
              <Stack
                direction="row"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Stack direction="row" gap={2}>
                  <CopytButton
                    onClick={() =>
                      bulkScanInUpdateSettings({isExpanded: !bulkScanInSettings.isExpanded})
                    }
                    variant="contained"
                    color="primary"
                    style={{padding: '6px 16px', borderRadius: '4px'}}
                  >
                    Settings
                  </CopytButton>

                  {isEnterprise && (
                    <Box width="200px" bgcolor="white" borderRadius={2}>
                      <SelectDropdown
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Store Location"
                        closeMenuOnSelect
                        onChange={(e) => {
                          bulkScanInUpdateLocation(e.label)
                        }}
                        isLoading={locations.isLoading}
                        options={locationOptions}
                        value={
                          bulkScanInPayload?.location ? {label: bulkScanInPayload.location} : undefined
                        }
                      />
                    </Box>
                  )}

                  <Box width="300px" bgcolor="white" borderRadius={2}>
                    <Input type="text" placeholder="Scan UPC" onKeyDown={handleScanUpc} />
                  </Box>
                </Stack>

                {isEnterprise && (
                  <CustomButton
                    disabled={
                      (!bulkScanInPayload.location && isEnterprise) || !bulkScanInPayload.products.length
                    }
                    icon={<QrCodeScanner />}
                    title="Generate Barcodes"
                    onClick={() => setModalType(ModalType.BARCODE_TEMPLATE)}
                    color="error"
                    style={{minWidth: '180px'}}
                  />
                )}
              </Stack>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack>
              <CopytLabel>Scan Autofill</CopytLabel>
              <RadioGroup
                row
                size="small"
                value={bulkScanInSettings.autofill.scan}
                onChange={(e) => {
                  bulkScanInUpdateSettings({autofill: {scan: e.target.value}})
                }}
              >
                {[BulkScanInAutofill.SCAN.DEFAULT, BulkScanInAutofill.SCAN.CONTINUES].map((radio) => (
                  <FormControlLabel
                    key={radio}
                    value={radio}
                    control={<Radio size="small" color="secondary" />}
                    label={radio}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <LinearProgress
          sx={{
            opacity: scanUpc.isLoading || createProducts.isLoading || listProducts.isLoading ? 1 : 0,
            backgroundColor: 'white',
            '& .MuiLinearProgress-bar': {
              backgroundColor: COLORS.PRIMARY,
            },
          }}
        />
      </Box>
      <Box px={2} pt={2} pb={0} height="0" flex={1}>
        <Stack backgroundColor="white" overflow="hidden" borderRadius={2} height="100%">
          <Stack
            paddingY={1}
            paddingLeft={1}
            paddingRight={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            bgcolor="var(--primary-light)"
          >
            <Box width="305px" bgcolor="white" borderRadius={2}>
              <Input
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>

            <Stack direction="row" gap={2}>
              <CopytButton
                disabled={
                  !bulkScanInPayload.products.length ||
                  createProducts.isLoading ||
                  listProducts.isLoading
                }
                onClick={bulkScanInClearProducts}
                variant="contained"
                color="secondary"
                style={{padding: '6px 16px', borderRadius: '4px'}}
              >
                Clear
              </CopytButton>
              <CopytButton
                disabled={
                  (!bulkScanInPayload.location && isEnterprise) ||
                  !bulkScanInPayload.products.length ||
                  createProducts.isLoading ||
                  listProducts.isLoading
                }
                onClick={async () => {
                  const createdProducts = await createProducts.mutateAsync()
                  console.log('createdProducts', createdProducts)
                  setCreatedProducts(createdProducts)
                  setModalType(ModalType.BULK_SCAN_IN_SUCCESS_CREATE)
                }}
                variant="contained"
                color="secondary"
                style={{padding: '6px 16px', borderRadius: '4px'}}
              >
                Add Items
              </CopytButton>
              <CopytButton
                disabled={
                  (!bulkScanInPayload.location && isEnterprise) ||
                  !bulkScanInPayload.products.length ||
                  createProducts.isLoading ||
                  listProducts.isLoading
                }
                onClick={() => {
                  setModalType(ModalType.LIST_ALL_PLATFORM)
                }}
                variant="contained"
                color="primary"
                style={{padding: '6px 16px', borderRadius: '4px'}}
              >
                List Items
              </CopytButton>
            </Stack>
          </Stack>

          <Stack overflow="auto" paddingTop={2} paddingRight={2} height="100%">
            {isEmpty(filteredProducts) ? (
              <EmptyData title="No Data Available." />
            ) : (
              filteredProducts
                .slice((pagination.page - 1) * pagination.limit, pagination.page * pagination.limit)
                .map((product, index) => (
                  <Products
                    key={product.id}
                    product={product}
                    setSelectedProduct={setSelectedProduct}
                    setModalType={setModalType}
                    currentDescriptionWildcardList={currentDescriptionWildcardList}
                  />
                ))
            )}
          </Stack>
        </Stack>

        {isBarcodeLabelReady && (
          <div style={{display: 'none'}}>
            <BarcodeLabels
              {...{barcodeTemplates, showBarcodeTemplate}}
              items={bulkScanInPayload.products.map((product) => ({
                ...product,
                location: bulkScanInPayload.location,
              }))}
              businessName={user?.businessName || 'Copyt'}
              logo={user?.platform?.logo_url ?? ''}
              url={user?.customWebsiteSearchUrl}
              ref={barcodeComponentRef}
            />
          </div>
        )}
      </Box>

      <Stack padding={2} direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={14} fontWeight={400} color="black">
          Items: {filteredProducts.length}
        </Typography>
        <Pagination
          count={Math.ceil(filteredProducts.length / pagination.limit)}
          onChange={(e, selectedPage) => {
            setPagination((prev) => ({...prev, page: selectedPage}))
          }}
          page={pagination.page}
          variant="outlined"
          shape="rounded"
          color="secondary"
        />
      </Stack>

      <BulkScanInModals
        duplicateProduct={duplicateProduct}
        modalType={modalType}
        setModalType={setModalType}
        modals={ModalType}
        barcodeTemplates={barcodeTemplates}
        setBarcodeTemplates={setBarcodeTemplates}
        onGenerateBarcodesClicked={() => onGenerateBarcodesClicked()}
        showBarcodeTemplate={showBarcodeTemplate}
        onShowBarcodeTemplate={setShowBarcodeTemplate}
        printSize={printSize}
        selectedProduct={selectedProduct}
        onCloseModal={() => {
          setSelectedProduct(undefined)
        }}
        setPrintSize={setPrintSize}
        listedProducts={listedProducts}
        createdProducts={createdProducts}
        onSaveChanges={async (payload) => {
          if (payload?.onListMultiple) {
            const listedProducts = await listProducts.mutateAsync(payload.onListMultiple)
            setListedProducts(listedProducts)
            setModalType(ModalType.BULK_SCAN_IN_SUCCESS_LIST)
          } else if (payload?.onBulkScanInDuplicate) {
            await duplicateProduct.mutateAsync({
              id: payload.onBulkScanInDuplicate.product.id,
              count: payload.onBulkScanInDuplicate.count,
            })
          }
        }}
      />
    </Stack>
  )
}

export default BulkScanIn

const Products = memo(
  ({product, setModalType, setSelectedProduct, currentDescriptionWildcardList}) => {
    const {consignors, subLocations} = useBulkScanIn()
    const {isEnterprise} = useGlobalStore()

    const bulkScanInUpdateProduct = useBulkScanInStore((state) => state.updateProduct)
    const bulkScanInRemoveProducts = useBulkScanInStore((state) => state.removeProduct)
    const bulkScanInUpdateProducts = useBulkScanInStore((state) => state.updateProducts)

    const handleSizeChange = useCallback(
      (size) =>
        bulkScanInUpdateProduct(product.id, {key: 'size', value: size}, currentDescriptionWildcardList),
      [product.id, bulkScanInUpdateProduct, currentDescriptionWildcardList],
    )

    const handleConditionChange = useCallback(
      (value) =>
        bulkScanInUpdateProduct(product.id, {key: 'condition', value}, currentDescriptionWildcardList),
      [product.id, bulkScanInUpdateProduct, currentDescriptionWildcardList],
    )

    const handleBoxConditionChange = useCallback(
      (value) =>
        bulkScanInUpdateProduct(
          product.id,
          {key: 'boxCondition', value},
          currentDescriptionWildcardList,
        ),
      [product.id, bulkScanInUpdateProduct, currentDescriptionWildcardList],
    )

    const handleRemoveProduct = useCallback(
      () => bulkScanInRemoveProducts(product.id),
      [product.id, bulkScanInRemoveProducts, currentDescriptionWildcardList],
    )

    return (
      <Scanned key={product.id} product={product}>
        <Scanned.Select.Size
          value={product?.size}
          onChange={handleSizeChange}
          category={product.category}
        />
        <Scanned.Select
          placeholder="Item Condition"
          options={condition_options}
          onChange={handleConditionChange}
          value={product?.condition ? {label: product.condition} : undefined}
        />
        <Scanned.Select
          placeholder="Box Condition"
          options={box_options}
          onChange={handleBoxConditionChange}
          value={product?.boxCondition ? {label: product.boxCondition} : undefined}
        />

        {isEnterprise && (
          <Scanned.Select
            placeholder="Sub Location"
            isLoading={subLocations.isLoading}
            onChange={(value) => {
              bulkScanInUpdateProduct(
                product.id,
                {key: 'subLocation', value: value},
                currentDescriptionWildcardList,
              )
            }}
            value={product?.subLocation ? {label: product.subLocation} : undefined}
            options={subLocations.data?.map((sublocation) => ({
              label: sublocation,
              value: sublocation,
            }))}
          />
        )}
        <Stack direction="row" gap={0.2}>
          <Stack position="relative">
            <Box position="absolute" bottom="100%">
              {/* <CopytLabel>Buy Price</CopytLabel> */}
            </Box>
            <Scanned.Input
              type="number"
              placeholder="Buy Price"
              value={product?.price}
              onChange={(value) => {
                bulkScanInUpdateProduct(
                  product.id,
                  {key: 'price', value},
                  currentDescriptionWildcardList,
                )
              }}
            />
          </Stack>
          <CopytButton
            onClick={() => {
              bulkScanInUpdateProducts(
                product.id,
                {key: 'price', value: product?.price},
                currentDescriptionWildcardList,
              )
            }}
            color="secondary"
            style={{padding: '6px 16px', borderRadius: '4px'}}
          >
            <KeyboardDoubleArrowDownIcon />
          </CopytButton>
        </Stack>

        <Stack direction="row" gap={0.2}>
          <Stack position="relative">
            <Box position="absolute" bottom="100%">
              {/* <CopytLabel>List Price</CopytLabel> */}
            </Box>
            <Scanned.Input
              type="number"
              placeholder="List Price"
              value={product?.desiredReturn}
              onChange={(value) =>
                bulkScanInUpdateProduct(
                  product.id,
                  {key: 'desiredReturn', value},
                  currentDescriptionWildcardList,
                )
              }
            />
          </Stack>
          <CopytButton
            onClick={() => {
              bulkScanInUpdateProducts(
                product.id,
                {key: 'desiredReturn', value: product?.desiredReturn},
                currentDescriptionWildcardList,
              )
            }}
            color="secondary"
            style={{padding: '6px 16px', borderRadius: '4px'}}
          >
            <KeyboardDoubleArrowDownIcon />
          </CopytButton>
        </Stack>

        {isEnterprise && (
          <Stack direction="row" gap={0.2}>
            <Scanned.Select
              placeholder="Owner"
              isFull
              isLoading={consignors.isLoading}
              onChange={(value) => {
                const consignor = {
                  consignor: consignors?.data?.find((_) => _?.email === value)?.owner || {},
                }

                bulkScanInUpdateProduct(
                  product.id,
                  {
                    key: 'consignorEmail',
                    value: value,
                  },
                  currentDescriptionWildcardList,
                )
                bulkScanInUpdateProduct(
                  product.id,
                  {
                    key: 'consign',
                    value: consignor,
                  },
                  currentDescriptionWildcardList,
                )
              }}
              value={product?.consignorEmail ? {label: product.consignorEmail} : undefined}
              options={consignors.data?.map((consignorEmail) => ({
                label: consignorEmail.email,
                value: consignorEmail.id,
              }))}
            />
            <CopytButton
              onClick={() => {
                bulkScanInUpdateProducts(
                  product.id,
                  {
                    key: 'consignorEmail',
                    value: product?.consignorEmail,
                  },
                  currentDescriptionWildcardList,
                )
              }}
              color="secondary"
              style={{padding: '6px 16px', borderRadius: '4px'}}
            >
              <KeyboardDoubleArrowDownIcon />
            </CopytButton>
          </Stack>
        )}

        <Scanned.Button
          onClick={() => {
            setSelectedProduct(product)
            setModalType(ModalType.BULK_SCAN_IN_DUPLICATE)
          }}
        >
          Duplicate
        </Scanned.Button>
        <Scanned.Button color="danger" onClick={handleRemoveProduct}>
          Remove
        </Scanned.Button>
      </Scanned>
    )
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.product) === JSON.stringify(nextProps.product) &&
      prevProps.currentDescriptionWildcardList.length === nextProps.currentDescriptionWildcardList.length
    )
  },
)
