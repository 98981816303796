import React from 'react'
import {Box} from '@mui/material'

import copytLogo from 'assets/img/copyt-logo-sidebar.svg'
import {useUser} from 'service/hook'

const Logo = (props) => {
  const {user} = useUser()

  return (
    <Box
      {...props}
      className="barcode-image"
      component="img"
      sx={{
        maxWidth: '85%',
        maxHeight: '85%',
        width: 'auto',
        height: 'auto',
      }}
      src={user?.platform?.logo_url || copytLogo}
    />
  )
}

export default Logo
