import React from 'react'
import {Box, Stack} from '@mui/material'
import Barcode from 'react-barcode'

import {generateTypography, getItem} from '../BarcodeLabels'
import Logo from './logo'

const Template4 = ({bottomStrings, topStrings, item, isDefaultSize}) => {
  return (
    <Stack direction="row" width="100%" height="100%" px={2} justifyContent="space-between">
      <Stack
        justifyContent="space-evenly"
        width="30%"
        maxWidth={isDefaultSize ? '30%' : '35%'}
        maxHeight={isDefaultSize ? '130px' : '200px'}
        alignItems="center"
        textAlign="center"
      >
        <Logo sx={{objectFit: 'contain'}} />
        {bottomStrings.map((str, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
            visibility={str?.isHidden ? 'hidden' : ''}
          >
            {generateTypography(getItem(str?.id, item), {
              fontSize: isDefaultSize ? '1.5rem' : '1.2rem',
            })}
          </Box>
        ))}
      </Stack>
      <Stack justifyContent="space-evenly" alignItems="center" width="100%">
        <Stack direction="row" justifyContent="center" width="100%">
          {topStrings.map((str, index) => (
            <Box
              key={index}
              justifyContent="center"
              alignItems="center"
              visibility={str?.isHidden ? 'hidden' : ''}
              display={str?.isHidden ? 'none' : 'flex'}
            >
              {generateTypography(getItem(str?.id, item), {
                fontSize: isDefaultSize ? '1.7rem' : '2rem',
              })}
            </Box>
          ))}
        </Stack>

        <Barcode
          value={item.internalSku}
          fontOptions="bold"
          height={isDefaultSize ? 40 : 35}
          width={isDefaultSize ? 2 : 1.5}
          fontSize={18}
        />
      </Stack>
    </Stack>
  )
}

export default Template4
