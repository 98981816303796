import React from 'react'
import {Box, Stack, Typography} from '@mui/material'
import Barcode from 'react-barcode'

import {ConsignorCode} from 'views_v2/modules/Inventory/components/modal-contents/MdlBarcodeTemplate'

const Template11 = ({showBarcodeTemplate}) => {
  return (
    <Box px={2}>
      <Box width="100%" minHeight="150px" bgcolor="var(--light)" borderRadius={2} p={1}>
        <Stack
          direction="row"
          flex={1}
          minHeight="130px"
          height="170px"
          maxHeight="170px"
          bgcolor="white"
          borderRadius={2}
          px={1}
          pb={2}
        >
          <Stack minWidth="100px" width="100px" justifyContent="space-between" height="100%">
            <Stack>
              <Box>
                <Typography fontWeight={600} fontSize={16} paddingTop={1}>
                  Item Name
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={14}>Size</Typography>
              </Box>
            </Stack>
            {showBarcodeTemplate?.listPrice && (
              <Box>
                <Typography fontSize={18} fontWeight={600}>
                  List Price
                </Typography>
              </Box>
            )}
          </Stack>

          <Barcode value="Internal Sku" width={2} />
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Stack>
      </Box>
    </Box>
  )
}

export default Template11
