import React from 'react'
import {Stack} from '@mui/material'
import Barcode from 'react-barcode'

import {generateTypography} from '../BarcodeLabels'
import Logo from './logo'

const Template9 = ({isDefaultSize, internalSku, location}) => {
  return (
    <Stack direction="row" width="100%" height="100%" px={2} justifyContent="space-between">
      <Stack
        justifyContent="space-evenly"
        width="30%"
        maxWidth={isDefaultSize ? '30%' : '35%'}
        maxHeight={isDefaultSize ? '130px' : '200px'}
        alignItems="center"
        textAlign="center"
      >
        <Logo sx={{objectFit: 'contain'}} />
      </Stack>
      <Stack justifyContent="space-evenly" alignItems="center" width="100%">
        <Barcode
          value={internalSku}
          fontOptions="bold"
          height={isDefaultSize ? 40 : 30}
          width={isDefaultSize ? 2 : 1.2}
          fontSize={18}
        />
        {generateTypography(location, {
          fontSize: location?.length > 19 && !isDefaultSize ? '14px' : '18px',
        })}
      </Stack>
    </Stack>
  )
}

export default Template9
